<template>
  <div class="preview">
    <img src="@/assets/preview.png" alt="Yolaine's Doll House" class="preview-image">
    <div class="preview-shadow"></div>
  </div>
  <div class="menu">
    <div class="menu-logo">
      <img src="@/assets/favicon.png" alt="Yolaine's Doll House">
      <span>Yolaine's <br/> Doll House</span>
    </div>
    <div class="menu-item">House</div>
    <div class="menu-item">Furniture</div>
    <div class="menu-item">About us</div>
    <div class="menu-cart">
      <img src="@/assets/cart.png" alt="Cart">
    </div>
  </div>
  <div class="shop">
    <div class="shop-categories">
      <div class="shop-category">Living room</div>
      <div class="shop-category">Kitchen</div>
      <div class="shop-category">Bedroom</div>
      <div class="shop-category">Bathroom</div>
      <div class="shop-category">Baby room</div>
      <div class="shop-category">Laundry room</div>
    </div>
    <div class="shop-items">
      <div class="shop-item">
        <img src="@/assets/table.png" alt="Table Set" class="shop-image">
      </div>
      <div class="shop-item">
        <img src="@/assets/sofa.png" alt="Sofa" class="shop-image">
      </div>
      <div class="shop-item">
        <img src="@/assets/bed.png" alt="Bed" class="shop-image">
      </div>
    </div>
  </div>
  <!--  <h1 class="yue">YUE</h1>-->
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(to right, #FDFBFB, #EBEDEE 70%);
}

* {
  font-family: 'Dancing Script', cursive;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

$font: 'Poppins', sans-serif;

::selection {
  background-color: #C3CFE2;
}

.yue {
  text-transform: uppercase;
  background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: {
    size: 20vw;
    family: $font;
  };
}

.preview {
  position: relative;
  display: flex;

  .preview-image {
    width: 100%;
  }

  .preview-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 64px 16px rgba(0, 0, 0, 0.75) inset;
  }
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .menu-logo {
    cursor: default;
    height: 100px;
    width: 100%;
    padding: 0 32px;
    font-size: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-right: black 1px solid;
    border-top: black 1px solid;
    border-bottom: black 1px solid;

    img {
      height: 80%;
      padding-right: 16px;
      object-fit: contain;
    }
  }

  .menu-item {
    cursor: pointer;
    height: 100px;
    width: 100%;
    padding: 0 32px;
    font-size: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    //box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.75) inset;
    border-right: black 1px solid;
    border-top: black 1px solid;
    border-bottom: black 1px solid;
    transition: 0.3s;

    &:hover {
      background-color: gray;
    }
  }

  .menu-cart {
    cursor: pointer;
    height: 100px;
    width: 100px;
    padding: 0 32px;
    font-size: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D9521C;
    border-top: black 1px solid;
    border-bottom: black 1px solid;
    transition: 0.3s;

    &:hover {
      background-color: rgb(151, 57, 19);
    }

    img {
      height: 80%;
      padding-right: 16px;
      object-fit: contain;
    }
  }
}

.shop {
  padding: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-image: url("assets/background.png");
  background-size: cover;

  .shop-categories {
    width: 30%;
    padding-right: 64px;

    .shop-category {
      cursor: pointer;
      padding: 4px 0;
      font-size: 150%;
      background-color: rgba(0, 0, 0, 0.3);
      box-sizing: border-box;

      &:nth-child(n+2) {
        border-top: gray 2px solid;
      }

      &:hover {
        outline: #D9521C 4px solid;
      }
    }
  }

  .shop-items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .shop-item {
      height: 200px;
      width: 300px;
      padding: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(2px);
      background-color: rgba(255, 255, 255, 0.3);
      transition: 0.3s;

      .shop-image {
        width: 90%;
        height: 90%;
        object-fit: contain;
        transition: 0.3s;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.5);

        .shop-image {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
